import { Col, Divider, Layout as AntdLayout, Row } from 'antd';
import { Link } from 'gatsby';
import React from 'react';

import Container from '@/components/Container';
import SocialIcons from '@/components/SocialIcons';

const Footer: React.FC<{ menuItems: { title: string; slug: string }[] }> = ({
  menuItems,
}) => (
  <AntdLayout.Footer style={{ paddingTop: 0 }}>
    <Divider className="mt-0 mb-8" />
    <Container className="text-center">
      <Row justify="center" className="text-base mx-4 font-medium">
        {menuItems.map((i, idx) => (
          <Col key={idx} className="mb-2 mx-4">
            <Link to={i.slug} style={{ color: '#6B7280' }}>
              {i.title}
            </Link>
          </Col>
        ))}
      </Row>
      <SocialIcons monochrome />
      <Row justify="center" className="text-base mb-4">
        <Col style={{ color: '#9CA3AF' }}>
          <span className="whitespace-nowrap">
            © НПО «Расепт», ООО, 2006—{new Date().getFullYear()}г.
          </span>{' '}
          <span className="whitespace-nowrap">Все права защищены.</span>
        </Col>
      </Row>
    </Container>
  </AntdLayout.Footer>
);

export default Footer;
