import { graphql, useStaticQuery } from 'gatsby';

export const useSiteMetadata = () => {
  const { site } = useStaticQuery<{
    site: {
      buildTime: string;
      siteMetadata: {
        title: string;
        siteUrl: string;
        logoUrl: string;
      };
    };
  }>(
    graphql`
      query SiteMetaData {
        site {
          buildTime(formatString: "YYYY-MM-DD")
          siteMetadata {
            title
            siteUrl
            logoUrl
          }
        }
      }
    `
  );

  return { ...site.siteMetadata, buildTime: site.buildTime };
};
