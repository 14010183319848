import { Col, Row } from 'antd';
import { ColProps } from 'antd/es/col';
import React from 'react';

import { Container as ContainerStyles } from './Container.module.css';

const Container: React.FC<ColProps> = ({ className = '', style, ...rest }) => (
  <Row>
    <Col
      {...rest}
      className={`${ContainerStyles} ${className}`}
      style={style}
    />
  </Row>
);

export default Container;
