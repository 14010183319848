import {
  AppstoreOutlined,
  DollarOutlined,
  MessageOutlined,
  ReadOutlined,
  UsergroupAddOutlined,
} from '@ant-design/icons';
import { Grid, Layout as AntdLayout } from 'antd';
import { LayoutProps } from 'antd/es/layout';
import React from 'react';

import Footer from '@/components/Footer';
import Navbar from '@/components/Navbar';
import { getCurrentBreakpoint } from '@/utils/mappings';

const navbarMenuItems = [
  { icon: <AppstoreOutlined />, title: 'Каталог', slug: '/catalog' },
  { icon: <DollarOutlined />, title: 'Купить', slug: '/buy' },
  { icon: <ReadOutlined />, title: 'Статьи', slug: '/articles' },
  { icon: <UsergroupAddOutlined />, title: 'Партнёрам', slug: '/partnership' },
  { icon: <MessageOutlined />, title: 'Контакты', slug: '/contact' },
];

const Layout: React.FC<LayoutProps> = ({
  children,
  className = '',
  ...rest
}) => {
  const screens = Grid.useBreakpoint();
  const currentBreakpoint = getCurrentBreakpoint(screens);

  return (
    <AntdLayout
      className={`${className} layout-${currentBreakpoint}`}
      {...rest}
    >
      <Navbar menuItems={navbarMenuItems} />
      <AntdLayout.Content>{children}</AntdLayout.Content>
      <Footer
        menuItems={[{ title: 'Главная', slug: '/' }, ...navbarMenuItems]}
      />
    </AntdLayout>
  );
};

export default Layout;
