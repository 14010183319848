import { Breakpoint } from 'antd/es/_util/responsiveObserve';

export const Breakpoints: Breakpoint[] = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCurrentBreakpoint = (
  screens: Partial<Record<Breakpoint, boolean>>
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
) => Breakpoints.filter((i) => screens[i]).pop()!;

export const categoryPhotos: {
  [title: string]: { categoryPhoto: string; categoryProductsPhoto: string };
} = {
  'Антисептики для рук': {
    categoryPhoto: 'gels_and_liquids.png',
    categoryProductsPhoto: 'antiseptics',
  },
  'Антибактериальное крем-мыло': {
    categoryPhoto: 'cream_soap.png',
    categoryProductsPhoto: 'cream-soap',
  },
  'Шампуни для волос': {
    categoryPhoto: 'shampoos.jpg',
    categoryProductsPhoto: 'shampoos',
  },
  'Антицеллюлитные средства': {
    categoryPhoto: 'anti-cellulite.jpg',
    categoryProductsPhoto: 'anticellulites',
  },
  'Гели для душа': {
    categoryPhoto: 'shower-gels.jpg',
    categoryProductsPhoto: 'showergel',
  },
  'Крем-мыло для интимной гигиены': {
    categoryPhoto: 'intimate_cream_soap.png',
    categoryProductsPhoto: 'intimate-cream-soaps',
  },
  'Гели для умывания': {
    categoryPhoto: 'gel.png',
    categoryProductsPhoto: 'washing',
  },
  'Средства для снятия макияжа': {
    categoryPhoto: 'oil-micellar.png',
    categoryProductsPhoto: 'cleansers',
  },
  'Средства по уходу за кожей лица': {
    categoryPhoto: 'tonic.png',
    categoryProductsPhoto: 'tonics',
  },
  'Детские шампуни': {
    categoryPhoto: 'baby_shampoos.jpg',
    categoryProductsPhoto: 'baby-shampoos',
  },
};
