import { graphql, useStaticQuery } from 'gatsby';

import { ProductType } from '@/types';

export type GroupedCategoryInfo = {
  [categoryName: string]: {
    title: string;
    products: {
      groupTitle: string;
      groupProducts: Pick<ProductType, 'name' | 'slug'>[];
    }[];
  };
};

export const useGroupedProduction = (): GroupedCategoryInfo => {
  const { allGroupedProductsByCategoryJson } = useStaticQuery<{
    allGroupedProductsByCategoryJson: {
      nodes: {
        id: string;
        title: string;
        products: GroupedCategoryInfo[string]['products'];
      }[];
    };
  }>(
    graphql`
      query GroupedProduction {
        allGroupedProductsByCategoryJson {
          nodes {
            id
            title
            products {
              groupTitle
              groupProducts {
                slug
                name {
                  type
                  series
                  option
                }
              }
            }
          }
        }
      }
    `
  );

  return allGroupedProductsByCategoryJson.nodes.reduce(
    (acc, { title, products }) => ({
      ...acc,
      [title]: {
        title,
        products,
      },
    }),
    {}
  );
};
