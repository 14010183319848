import { graphql, useStaticQuery } from 'gatsby';

export const useAllProductionInfo = () => {
  const data: {
    allProductionJson: {
      nodes: {
        id: string;
        title: string;
        slug: string;
      }[];
    };
  } = useStaticQuery(graphql`
    query AllProductionInfo {
      allProductionJson {
        nodes {
          id
          title
          slug
        }
      }
    }
  `);

  return data.allProductionJson.nodes;
};
