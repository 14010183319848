import { MoreOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Grid, Layout, Menu, Row } from 'antd';
import { Link } from 'gatsby';
import Hamburger from 'hamburger-react';
import React, { useState } from 'react';

import Container from '@/components/Container';
import { useAllProductionInfo } from '@/hooks/useAllProductionInfo';
import {
  GroupedCategoryInfo,
  useGroupedProduction,
} from '@/hooks/useGroupedProduction';
import { getCurrentBreakpoint } from '@/utils/mappings';

// TODO: https://www.gatsbyjs.com/docs/reference/release-notes/migrating-from-v2-to-v3/#css-modules-are-imported-as-es-modules
import * as styles from './Navbar.module.css';

const categoryIcons: {
  [title: string]: { iconName: string; shorthandText: React.ReactNode };
} = {
  'Антисептики для рук': {
    iconName: '/icons/antiseptic.svg',
    shorthandText: (
      <>
        <div>Антисептики</div>
        <div>для рук</div>
      </>
    ),
  },
  'Антибактериальное крем-мыло': {
    iconName: '/icons/cream-soap.svg',
    shorthandText: (
      <>
        <div>Антибактериальное</div>
        <div>крем-мыло</div>
      </>
    ),
  },
  'Шампуни для волос': {
    iconName: '/icons/hair-shampoo.svg',
    shorthandText: (
      <>
        <div>Шампуни</div>
        <div>для волос</div>
      </>
    ),
  },
  'Антицеллюлитные средства': {
    iconName: '/icons/anticelulite.svg',
    shorthandText: (
      <>
        <div>Антицеллюлитные</div>
        <div>средства</div>
      </>
    ),
  },
  'Гели для душа': {
    iconName: '/icons/shower.svg',
    shorthandText: (
      <>
        <div>Гели</div>
        <div>для душа</div>
      </>
    ),
  },
  'Крем-мыло для интимной гигиены': {
    iconName: '/icons/intimate-soap.svg',
    shorthandText: (
      <>
        <div>Крем-мыло для</div>
        <div>интимной гигиены</div>
      </>
    ),
  },
  'Гели для умывания': {
    iconName: '/icons/washing.svg',
    shorthandText: (
      <>
        <div>Гели</div>
        <div>для умывания</div>
      </>
    ),
  },
  'Средства для снятия макияжа': {
    iconName: '/icons/makeup-remover.svg',
    shorthandText: (
      <>
        <div>Средства для</div>
        <div>снятия макияжа</div>
      </>
    ),
  },
  'Средства по уходу за кожей лица': {
    iconName: '/icons/tonic.svg',
    shorthandText: (
      <>
        <div>Средства по уходу</div>
        <div>за кожей лица</div>
      </>
    ),
  },
  'Детские шампуни': {
    iconName: '/icons/baby-shampoo.svg',
    shorthandText: (
      <>
        <div>Детские</div>
        <div>шампуни</div>
      </>
    ),
  },
};

export const createCategoryDropdownOverlayMenu = ({
  products,
}: GroupedCategoryInfo[string]) => (
  <Menu>
    {products.map(({ groupTitle, groupProducts }) => (
      <Menu.ItemGroup title={groupTitle} key={groupTitle}>
        {groupProducts.map((i) => (
          <Menu.Item key={i.slug}>
            <Link to={`/catalog/${i.slug}`}>{i.name.option}</Link>
          </Menu.Item>
        ))}
      </Menu.ItemGroup>
    ))}
  </Menu>
);

const Navbar: React.FC<any> = ({ menuItems }: any) => {
  const screens = Grid.useBreakpoint();
  const currentBreakpoint = getCurrentBreakpoint(screens);
  const itemFlexWidth = {
    xxl: 10,
    xl: 10,
    xs: 50,
    sm: 50,
    md: 20,
    lg: 20,
  };
  const disableDropdown = !currentBreakpoint?.includes('xl');
  const [isOpen, setOpen] = useState(false);
  const groupedProductsByCategory = useGroupedProduction();
  const allProductionInfo = useAllProductionInfo();

  // TODO: Use <Headroom /> via 'react-headroom'
  return (
    <Layout.Header className={styles.Navbar}>
      <Container>
        <Row gutter={32} justify="space-between" wrap={false}>
          <Col>
            <Link to="/" className="block py-5" aria-label="Rasept">
              <img
                src="/logo/rasept-logo-text-white.svg"
                alt="Rasept"
                style={{ height: 24, width: 137 }}
              />
            </Link>
          </Col>
          <Col style={{ order: currentBreakpoint === 'xs' ? -1 : undefined }}>
            <Dropdown
              forceRender
              overlayClassName={styles.NavbarDropdown}
              onVisibleChange={setOpen}
              trigger={['click']}
              overlay={() => (
                <div className={styles.NavbarDropdownOverlay}>
                  <Container>
                    <Row className="pt-2">
                      {allProductionInfo.map(({ title, slug }) => (
                        <Dropdown
                          disabled={disableDropdown}
                          arrow
                          placement="bottomCenter"
                          key={slug}
                          overlay={createCategoryDropdownOverlayMenu(
                            groupedProductsByCategory[title]
                          )}
                        >
                          <Col
                            flex={`${
                              currentBreakpoint
                                ? itemFlexWidth[currentBreakpoint]
                                : 100 / allProductionInfo.length
                            }%`}
                            className={styles.NavbarCategoryItem}
                          >
                            <Link
                              to={`/catalog/${slug}`}
                              className="w-full"
                              onClick={() => setOpen(false)}
                            >
                              <img
                                src={categoryIcons[title].iconName}
                                className="h-12 m-auto"
                                alt={title}
                              />
                              <div className={styles.NavbarDropdownText}>
                                {categoryIcons[title].shorthandText}
                              </div>
                            </Link>
                          </Col>
                        </Dropdown>
                      ))}
                    </Row>
                  </Container>
                </div>
              )}
            >
              <Button
                className={styles.HamburgerButton}
                ghost
                // type="default"
                size="large"
                icon={
                  <Hamburger
                    label="Каталог"
                    size={28}
                    rounded
                    toggled={isOpen}
                  />
                }
              />
            </Dropdown>
          </Col>
          <Col flex="auto">
            {/* <div
              style={{
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                width: ['sm', 'xs'].includes(currentBreakpoint!)
                  ? 1
                  : undefined,
              }}
            > */}
            <Menu
              mode="horizontal"
              triggerSubMenuAction="click" // workaround
              forceSubMenuRender
              className={styles.NavbarMenu}
              // theme="dark"
              overflowedIndicator={<MoreOutlined style={{ fontSize: 36 }} />}
              //   <Button
              //     style={{
              //       padding: '0px 42px 0px 16px',
              //     }}
              //     className="align-middle h-12"
              //     type="default"
              //     size="large"
              //     icon={<MoreOutlined style={{ fontSize: 28 }} />}
              //   />
              // }
              // style={{
              //   textAlign: 'right',
              //   background: 'transparent',
              // }}
            >
              {menuItems.map((i: any) => (
                <Menu.Item icon={i.icon} key={i.slug}>
                  <Link to={i.slug}>{i.title}</Link>
                </Menu.Item>
              ))}
            </Menu>
          </Col>
        </Row>
      </Container>
    </Layout.Header>
  );
};

export default Navbar;
