import { PageProps } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import { useSiteMetadata } from '@/hooks/useSiteMetadata';

// https://github.com/gatsbyjs/gatsby-starter-shopify/blob/main/src/components/seo.jsx
const SEO: React.FC<{
  title: string;
  description: string;
  uri: PageProps['uri'];
  image: null | string;
}> = ({ title, description, image, uri }) => {
  if (!uri) {
    throw new Error('No URI provided in SEO Component');
  }
  const { buildTime, title: brandName, siteUrl, logoUrl } = useSiteMetadata();
  const imageUrl = `${siteUrl}${image || logoUrl}`;

  const articleLdJson = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'Article',
    url: uri,
    headline: title,
    description,
    name: title,
    dateModified: buildTime,
    author: brandName,
    image: imageUrl,
  });

  const webPageLdJson = JSON.stringify({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `${siteUrl}${uri}`,
    name: brandName,
    description,
  });

  return (
    <Helmet title={title} titleTemplate={`%s | ${brandName}`}>
      {/* Technical */}
      <html lang="ru-RU" dir="ltr" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, viewport-fit=cover"
      />

      {/* Basic */}
      <meta name="description" content={description} />
      <meta name="image" content={imageUrl} />

      {/* Twitter */}
      <meta name="twitter:site" content="@RaseptCom" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imageUrl} />
      <meta name="twitter:image:alt" content={description} />

      {/* OpenGraph */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:url" content={`${siteUrl}${uri}`} />
      <meta property="og:locale" content="ru_RU" />
      <meta property="og:site_name" content={brandName} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:image:alt" content={description} />

      {uri.startsWith('/articles/') ? (
        <script type="application/ld+json">{articleLdJson}</script>
      ) : null}

      <script type="application/ld+json">{webPageLdJson}</script>
    </Helmet>
  );
};

export default SEO;
