import { Col, Row } from 'antd';
import React from 'react';

const socialIcons = [
  ['vk', 'https://vk.com/rasept'],
  ['instagram', 'https://www.instagram.com/raseptcom/'],
  ['facebook', 'https://www.facebook.com/RaseptCom/'],
  ['ok', 'https://ok.ru/group/58832528343234'],
  ['twitter', 'https://twitter.com/RaseptCom'],
];

const SocialIcons: React.FC<{ monochrome?: boolean }> = ({ monochrome }) => (
  <Row
    justify="center"
    className={`mt-4 mb-6 ${monochrome ? 'filter contrast-0' : ''}`}
  >
    {socialIcons.map(([name, url], idx) => (
      <Col
        key={name}
        className={
          idx === 0 ? 'mr-4' : idx === socialIcons.length - 1 ? 'ml-4' : 'mx-4'
        }
      >
        <a href={url} rel="noopener noreferrer" target="_blank">
          <img
            src={`/icons/social/${name}.svg`}
            alt={name}
            style={{ height: 32 }}
          />
        </a>
      </Col>
    ))}
  </Row>
);

export default SocialIcons;
